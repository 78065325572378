// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Reset
@import '~sass-reset';

.MuiCollapse-wrapper .MuiListItemIcon-root {
  margin-right: 16px;
}

.MuiButtonBase-root .MuiListItemIcon-root {
  width: 40px;
}

li.RaMenuItemLink-root-36 {
  height: 36px;
}

a.MuiButton-contained:hover {
  color: #000;
  background-color: #74d6fc;
}

a:hover {
  color: #74d6fc;
}

.MuiDrawer-root {
  a:hover,
  li:hover {
    color: #fb963a;
  }
}

.MuiTable-root {
  background: #303030;
}

.MuiTableBody-root,
.MuiDrawer-root {
  background: #424242;
}

@media only screen and (max-width: 959px) {
  .MuiDrawer-root {
    background: none;
  }
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  width: 100%;
}

.MuiSnackbarContent-message {
  white-space: pre;
}

.recharts-default-tooltip {
  background-color: #545454 !important;
  border-radius: 4px;
  border: none !important;
}
